<template>
    <div> 
    <Bar :chart-options="chartOptions" :chart-data="chartData"  :dataset-id-key="datasetIdKey"
        :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width" :height="height"/>
            </div>
  
</template>
  
<script>
import { Bar } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {

    name: 'PieChart',
    components: {
        Bar
    },
    props: {
        total:{type:Number},
        desmarcados:{type:Number},
        dia:{type:Number},
        
        chartId: {
            type: String,
            default: 'pie-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 200
        },
        height: {
            type: Number,
            default: 300
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },    
    data: () => ({
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }
    }),
    computed:{
        chartData(){ 
           
            // console.log(this.dia)
            var arr = [this.desmarcados,this.total-this.desmarcados]  
            let datasets =  [
                {
                    label:'Tudo',
                    labels: ["Desmarcados","Marcados","Agendamento dia Hoje"],
                    backgroundColor: ['#41B883', '#E46651','#E46651',],
                    data: arr
                }
            ] 
            return {
                labels:['Desmarcados', 'Marcados'],
                datasets:datasets
            }
        }
    },
    
   
}

</script>
  