<template>
    <div>
        <Bar :chart-data="chartData" :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses"
            :styles="styles" :width="width" :height="height" />
          
    </div>
   
</template>
  
<script>
import { Bar } from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
import {contadorDias} from '../../utils/DiasSemanaExtenso'

export default {

    name: 'GraficoSemana',
    components: {
        Bar
    },
    props: {
        base: {
            type: Array
        },
        chartId: {
            type: String,
            default: 'pie-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 40
        },
        height: {
            type: Number,
            default: 10
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },

    data: () => ({
        semana: null,
       


    }),
    
    computed: {

        
        chartData() {
            // RETORNAR UM ARRAY COM AS DATAS
            //  const hoje=new Date()
            //  const seteDiasAtras = new Date(hoje.getTime() - (7 * 24 * 60 * 60 * 1000))
            //  const c=seteDiasAtras.toISOString()
            //  let a=this.base.filter(q=>q.created_at.slice(0, 10)>=c&&q.created_at.slice(0, 10)<=hoje.toISOString())
            
            let arr = Object.keys(this.base).map((e) => {
                if (this.base[e].cd_paciente !== 'None') {
                    return this.base[e].created_at.slice(0, 10)
                }
            })
            //função da pasta utills
            let diasExtenso=contadorDias(arr)
           
           
            let diaSemana = ["Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sabado", "Domingo"]
            let data = Object.values(diasExtenso)
            let labels = Object.keys(diasExtenso).map(
                (v) => diaSemana[new Date(v).getDay()] + ' ' + new Date(v).toLocaleDateString()
            )
            // console.log(data)
            let datasets = [
                {
                    label: 'Tudo',
                    labels: labels,
                    backgroundColor: ['#41B883', '#E46651', '#E46651', '#00BFFF', '#ADFF2F', '#008080','#b98bd8'],
                    data: data
                }
            ]
            return {
                labels: labels,
                datasets: datasets,

            }


        }

    },


}


</script>
  