<template >
  <v-card class="spacing-playground pa-6">

    <v-row dense> <v-col cols="5">
        <v-text-field v-model="data_inicial" label="Data inicial" type="date">
        </v-text-field>
      </v-col>
      <v-col cols="5">
        <v-text-field v-model="data_final" label="Data final" type="date">
        </v-text-field>
      </v-col>
      <v-col class="mt-3">
        <v-btn @click="pesquisar()" color="primary">Pesquisar</v-btn>
      </v-col>
    </v-row>

    <v-data-table dense :headers="headers" :items="historico" item-key="name" class="elevation-1" :search="search"
      :loading="loading" :lazy="true" :items-per-page="10"  @update:page="getUser">
      <template v-slot:top>
        <v-text-field v-model="search" label="Pesquisar" class="mx-4" single-line hide-details></v-text-field>
      </template>
      <template v-slot:[`item.dt_nascimento`]="{ item }">

        {{ item.dt_nascimento | formatDataTime }}
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | formatDataTime }}
      </template>

    </v-data-table>
    <v-card>
      <h1>Total de atendimentos:{{ all }}</h1>
    </v-card>
    <Pie :total="all" :desmarcados="desmarcados" />
    <v-card>
      <h1>Total de agendamentos Hoje:{{ day }}</h1>

      <Pie :total="day" :desmarcados="daydesmarcado" />
    </v-card>
    <v-card>
      <h1> Semana </h1>

    </v-card>
    <v-card>
      <GraficoSemana :base="base" />
    </v-card>
  </v-card>
</template>

<script>

import api from '@/http';
import Pie from '../components/graficos/Pie.vue'
import GraficoSemana from '../components/graficos/GraficoSemana.vue'


let dttmp = new Date()
let dttqe = new Date()
let dt = new Date(dttqe.getTime() - (1 * 24 * 60 * 60 * 1000))
let dtf = new Date(dttmp.getTime() + (6 * 24 * 60 * 60 * 1000))
let inicio = dt.toISOString().split('T')[0]
let end = dtf.toISOString().split('T')[0]
export default {
  components: {
    Pie, GraficoSemana
  },
  data() {
    return {
      historico: [],
      resultado: '',
      search: '',
      users: [this.user],
      diasSemana: ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado', 'Domingo'],
      desmarcados: 0,
      day: 0,
      daydesmarcado: 0,
      all: 0,
      data_inicial: inicio,
      data_final: end,
      base: [],
      headers: [
        {
          text: 'Codigo Paciente',
          align: 'start',
          sortable: false,
          value: 'cd_paciente',
        },
        { text: 'Nome', value: 'nm_paciente' },
        { text: 'Data de nascimento', value: 'dt_nascimento' },
        { text: 'Usuario', value: 'cd_usuario' },
        { text: 'Observação', value: 'observacao' },
        { text: 'Tipo de situação', value: 'tp_situacao' },
        { text: 'Item_agendamento', value: 'cd_item_agendamento' },
        { text: 'Criação', value: 'created_at' },

      ],
      loading: false,



    }
  },

  methods: {

    groupBy(list, keyGetter) {

      const map = new Map();
      list.map((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      })
      return map;
    },

    pesquisar() {
      this.getUser()
    },
    getUser() {

      api
        .get(`atendimentos/atendimentos/?start_date=${this.data_inicial}&end_date=${this.data_final}`)
        .then((res) => {
          res.data;
          console.log(res.data)
          let data = res.data
          this.base = res.data

          const A = data.map(function (e) {

            return (
              {
                cd_paciente: e.cd_paciente,
                nm_paciente: e.nm_paciente,
                dt_nascimento: e.dt_nascimento,
                cd_usuario: e.cd_usuario,
                observacao: e.ds_observacao,
                tp_situacao: e.tp_situacao,
                cd_item_agendamento: e.cd_item_agendamento,
                created_at: e.created_at

              }
            )


          })
          this.users = A
          const timeElapsed = Date.now();

          const today = new Date(timeElapsed);
          const dateQ = today.toISOString();

          this.historico = A
          let a = A.map((a) => a.nm_paciente === 'None')
          let d = a.filter(Boolean).length
          let t = a.length

          this.all = t
          this.desmarcados = d
          const grupoDay = this.groupBy(this.historico, dat => dat.created_at.slice(0, 10) == dateQ.slice(0, 10))
          //pega usuarios none
          let esc = grupoDay.get(true).map((b) => b.nm_paciente === 'None')
          let desmarcados = esc.filter(Boolean).length

          //Agendamentos por dia atual
          this.day = grupoDay.get(true).length
          //desmarcados por dia atual
          this.daydesmarcado = desmarcados
          //Desmarcados total
          this.quantidade = data.length

        })
        .catch((error) => {
          console.log(error);
        })



    },

  },
  mounted() {
    this.getUser()

  }



}
</script>